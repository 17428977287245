<template>
    <div
        :class="[
            'material-history-modal',
            {
                '--open': open,
            },
        ]"
    >
        <b-sidebar
            id="material-history-modal"
            :visible="open"
            shadow
            right
            bg-variant="white"
            backdrop
            no-close-on-backdrop
            no-close-on-esc
        >
            <template #header>
                <Header />
            </template>

            <template>
                <Content />
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import { BSidebar, VBToggle } from "bootstrap-vue";
import Header from "./Header.vue";
import Content from "./Content.vue";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
    components: {
        Header,
        Content,
        BSidebar,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    computed: {},
    props: {
        open: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
    methods: {
        ...mapMutations("production_progress", [
            "UPDATE_MATERIAL_HISTORY_MODAL_HISTORY",
        ]),
        sortHistoryByDate(a, b) {
            const dataA = moment(a.actionTime);
            const dataB = moment(b.actionTime);

            if (dataA.isBefore(dataB)) {
                return 1; // dataA vem antes que dataB
            }
            if (dataA.isAfter(dataB)) {
                return -1; // dataA vem depois que dataB
            }
            return 0; // As datas são iguais
        },
    },
    mounted() {
        this.UPDATE_MATERIAL_HISTORY_MODAL_HISTORY(
            [
                {
                    id: 35,
                    name: "nome material 1",
                    actionId: 1, // adicionado
                    actionTime: "2024-11-25 00:02:00",
                    photo: "https://sm.ign.com/ign_br/screenshot/default/link_t3cf.png",
                    code: "código material 1",
                    user: "nome do usuário",
                },
                {
                    id: 35,
                    name: "nome material 1",
                    actionId: 2, // atualizado
                    actionTime: "2024-11-25 00:01:00",
                    photo: "https://sm.ign.com/ign_br/screenshot/default/link_t3cf.png",
                    oldValue: 4,
                    newValue: 13,
                    code: "código material 1",
                    user: "nome do usuário",
                },
                {
                    id: 35,
                    name: "nome material 1",
                    actionId: 3, // removido7
                    actionTime: "2024-11-27 00:00:00",
                    photo: "https://sm.ign.com/ign_br/screenshot/default/link_t3cf.png",
                    code: "código material 1",
                    user: "nome do usuário",
                },
            ].sort(this.sortHistoryByDate),
        );
    },
};
</script>

<style lang="scss">
body:has(.material-history-modal.--open) {
    overflow: hidden;
}

#material-history-modal {
    min-width: 600px;
    max-width: 600px;
}

@media (max-width: 768px) {
    #material-history-modal {
        min-width: 100dvw;
        max-width: 100dvw;
    }
}
</style>

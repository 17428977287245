<template>
    <div class="material-history-modal-content__container">
        <material-history-card
            v-for="(register, index) in materialHistoryModal.history"
            :key="`register-${index}`"
            :register="register"
        />
    </div>
</template>

<script>
import MaterialHistoryCard from "@/views/pages/operator/components/MaterialHistoryCard";
import { mapGetters } from "vuex";

export default {
    components: {
        MaterialHistoryCard,
    },
    computed: {
        ...mapGetters("production_progress", ["materialHistoryModal"]),
    },
};
</script>

<style lang="scss" scoped>
#material-history-modal {
    .material-history-modal-content__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 24px 29px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
        }
    }
}

@media (max-width: 570px) {
    #material-history-modal {
        .material-history-modal-content__container {
            padding: 24px 16px !important;
        }
    }
}
</style>

<template>
    <div :class="['info-box__container', { '--odd': odd }]">
        <span class="info-box__label">{{ label }}</span>

        <div class="info-box__content">
            <slot v-if="!text" />

            <span class="info-box__text" v-else>{{ text }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },

        odd: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>
#material-history-card {
    .info-box__container {
        padding: 16px;
        background: #fff;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
        justify-content: space-between;

        &:nth-child(odd) {
            background: #fff;
        }

        &:nth-child(even) {
            background: #fafafa;
        }

        .info-box__label {
            color: #998f8a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            white-space: nowrap;
        }

        .info-box__content {
            display: flex;
            overflow: hidden;
            justify-content: flex-end;
            width: 100%;

            .info-box__text {
                white-space: nowrap;
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 571px) {
    #material-history-card {
        .info-box__container {
            .info-box__label {
                font-size: 16px !important;
                line-height: 22px !important;
            }
        }
    }
}

@media (max-width: 570px) {
    #material-history-card {
        .info-box__container {
            .info-box__label {
                font-size: 12px !important;
                line-height: 16px !important;
            }

            .info-box__content {
                .info-box__text {
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
    }
}
</style>

<template>
    <div id="material-history-card">
        <div class="material-history-card__header">
            <span class="material-history-card__material-name">
                {{ register.name }}
            </span>
            <sys-button
                @click="isCollapsed = !isCollapsed"
                scheme="square"
                :size="windowWidth <= 570 ? 'xs' : 'md'"
                variant="outline"
                icon
            >
                <ArrowIcon
                    :class="['material-history-card__arrow-icon', { '--open': !isCollapsed }]"
                />
            </sys-button>
        </div>
        <div
            class="material-history-card__content"
            v-show="!isCollapsed"
        >
            <info-box label="Status">
                <div
                    class="material-history-card__order-status"
                    :style="getBackgroundColorLegend(register.actionId)"
                >
                    <span>
                        {{ formatActionName(register.actionId) }}
                    </span>
                </div>
            </info-box>
            <info-box
                :label="$t('ProductionProgress.Date')"
                :text="formatDate(register.actionTime)"
            />
            <info-box
                :label="$t('ProductionProgress.Code')"
                :text="register.code"
            />

            <info-box
                v-if="
                    !isNullOrUndefined(register.newValue) && !isNullOrUndefined(register.oldValue)
                "
                :label="$t('ProductionProgress.ConsumedQuantity')"
                :text="`${$t('ProductionProgress.From')}
                    ${register.oldValue} ${$t('ProductionProgress.To')}
                    ${register.newValue}`"
            />

            <info-box :label="$t('ProductionProgress.User')">
                <div class="material-history-card__avatar">
                    <b-avatar
                        v-if="register.photo"
                        variant="light-primary"
                        :src="register.photo"
                        :size="handleAvatarResize()"
                    />

                    <b-avatar
                        v-else
                        variant="light-secondary"
                        :size="handleAvatarResize()"
                    >
                        <span>{{ getInitials(register.user) }}</span>
                    </b-avatar>

                    <span class="material-history-card__user-name">
                        {{ register.user }}
                    </span>
                </div>
            </info-box>
        </div>
    </div>
</template>

<script>
    import ArrowIcon from "@core/assets/icons/arrow-icon.svg";
    import SysButton from "@core/components/sys-button";
    import InfoBox from "./InfoBox.vue";

    import moment from "moment";
    import { mapGetters } from "vuex";
    import { BAvatar } from "bootstrap-vue";
    import materialsStatusEnum from "../../enums/materialsStatus";

    export default {
        components: {
            ArrowIcon,
            InfoBox,
            SysButton,
            BAvatar
        },
        props: {
            register: {
                type: Object,
                required: true,
                default: () => ({
                    id: null,
                    name: "",
                    actionId: null,
                    actionTime: "",
                    oldValue: null,
                    newValue: null,
                    code: "",
                    user: "",
                    photo: ""
                })
            }
        },
        computed: {
            ...mapGetters("app", ["windowWidth"])
        },
        data: () => ({
            isCollapsed: true
        }),
        methods: {
            isNullOrUndefined(value) {
                return value === null || value === undefined;
            },
            getInitials(fullName) {
                const nameParts = fullName.split(" ");
                const firstInitial = nameParts[0][0];
                const lastInitial = nameParts[nameParts.length - 1][0];

                return firstInitial + lastInitial;
            },
            formatDate(date) {
                if (!date) return "";
                return moment(date).format("DD-MM-YYYY HH:mm");
            },
            handleAvatarResize() {
                if (this.windowWidth <= 570) return 24;
                return 40;
            },
            getBackgroundColorLegend(actionId) {
                if (actionId === materialsStatusEnum.adicionado)
                    return `color:${"#4CAF50"}; background-color:${"#E8F5E9"}`;

                if (actionId === materialsStatusEnum.atualizado)
                    return `color:${"#FF9800"}; background-color:${"#FFF3E0"}`;

                if (actionId === materialsStatusEnum.removido)
                    return `color:${"#F44336"}; background-color:${"#FFEBEE"}`;

                return `background-color:secondary`;
            },
            formatActionName(actionId) {
                if (actionId === materialsStatusEnum.adicionado) return "Adicionado";
                if (actionId === materialsStatusEnum.atualizado) return "Atualizado";
                if (actionId === materialsStatusEnum.removido) return "Removido";

                return "";
            }
        }
    };
</script>

<style lang="scss" scoped>
    #material-history-card {
        padding: 15px;
        border-radius: 6px;
        border: 1px solid #cfc4be;
        width: 100%;

        .material-history-card__header {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: 16px;

            .material-history-card__material-name {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                color: #4c4541;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .material-history-card__arrow-icon {
                transform: rotate(90deg);

                &.--open {
                    transform: rotate(-90deg);
                }
            }
        }

        .material-history-card__content {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            padding-top: 15px;
            border-top: 1px solid #cfc4be;
            gap: 8px;

            .material-history-card__order-status {
                display: flex;
                align-items: center;
                padding: 1px 9px;
                height: 28px;
                border-radius: 4px;
                overflow: hidden;

                span {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .material-history-card__avatar {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                gap: 7.45px;
                overflow: hidden;

                .material-history-card__user-name {
                    white-space: nowrap;
                    color: #4c4541;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #material-history-card {
            .material-history-card__header {
                .material-history-card__material-name {
                    font-size: 26px !important;
                    line-height: 36px !important;
                }
            }
        }
    }

    @media (max-width: 570px) {
        #material-history-card {
            .material-history-card__content {
                .material-history-card__avatar {
                    .material-history-card__user-name {
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }

                .material-history-card__order-status {
                    height: 18px;
                    span {
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }
            }
        }
    }
</style>
